import { Box, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ExpandView from "./ExpandView";
import { MenuItems } from "../MenuList";
import ItemView from "./ItemView";
import logo from "../../../Assets/favicon-96x96.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { motion } from "framer-motion";
function DrawerView({ handleDrawer, openD, drawerValue }) {
  return (
    <div
      style={{ zIndex: "1" }}
      onMouseLeave={() => {
        if (!drawerValue) {
          handleDrawer(1, false);
        }
      }}
      onMouseEnter={() => {
        if (!drawerValue) {
          handleDrawer(1, true);
        }
      }}
    >
      <Paper
        component={motion.div}
        animate={{
          width: openD ? "250px" : "80px",
        }}
        elevation={!drawerValue && openD ? 10 : 0}
        sx={{
          height: "100vh",

          borderRadius: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "all .05 ease-in-out",
          ...(!drawerValue && { position: "absolute" }),
        }}
      >
        <BrandView handleDrawer={handleDrawer} openD={openD} />
        <BottomListView openD={openD} />
      </Paper>
    </div>
  );
}

export default DrawerView;

function BrandView({ handleDrawer, openD }) {
  return (
    <Box
      sx={{
        height: "72px",
        width: "100%",
        m: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "dotted",
        borderBottomWidth: "2px",
        borderBottomColor: "divider",
        pb: 1.5,
      }}
    >
      <Box
        sx={{
          m: 1,
          display: "flex",
          alignItems: "center",
          objectFit: "fill",
        }}
      >
        <motion.img
          animate={{
            height: openD ? "45px" : "30Px",
          }}
          alt="logo"
          src="android-chrome-512x512.png"
        />
        <motion.span
          animate={{
            visibility: openD ? 1 : 0,
            scale: openD ? 1 : 0,
            width:openD ? "100px" :"0px"
          }}
        >
          <Typography
            sx={{
              ml: 1,
            }}
            variant="h5"
          >
            Reuz
          </Typography>
        </motion.span>
      </Box>
      <Paper
        elevation={0}
        sx={{
          ...(!openD && { height: "30px", width: "30px" }),
          mr: 1,
          color: "background.default",
        }}
      >
        <IconButton
          onClick={() => {
            handleDrawer(2);
          }}
          sx={{ ...(!openD && { p: 0.6 }) }}
        >
          {openD ? (
            <KeyboardDoubleArrowLeftIcon
              sx={{
                ...(!openD && { height: "20px", width: "20px", p: 0 }),
              }}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              sx={{
                ...(!openD && { height: "20px", width: "20px", p: 0 }),
              }}
            />
          )}
        </IconButton>
      </Paper>
    </Box>
  );
}

function BottomListView({ openD }) {
  return (
    <Box
      sx={{
        flex: "1",
        width: openD ? "230px" : "80px",
        px: 1,
        pt: 1.5,
      }}
    >
      {MenuItems.map((v) => {
        return v.isExpand ? (
          <ExpandView item={v} key={v.id} openD={openD} />
        ) : (
          <ItemView item={v} key={v.id} openD={openD} changeParent={false} />
        );
      })}
    </Box>
  );
}
