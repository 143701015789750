
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import React from 'react'

function MultiSelection({selected,handleChange,names,lable,multiple=true,width= 300,sx={}}){

  return (
    
    <FormControl sx={{ m: 1, width: width,...sx }}>
      <InputLabel id="demo-multiple-checkbox-label">{lable}</InputLabel>
      <Select
      defaultValue=""
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple={multiple}
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput label={lable} />}
        renderValue={(select) => select.join(', ')}
        // MenuProps={MenuProps}
      >
        {names.map((name) => (
          <MenuItem key={name.id} value={name.name}>
            <Checkbox checked={selected.indexOf(name.name) > -1} />
            <ListItemText primary={name.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
 


  )
}

export default MultiSelection