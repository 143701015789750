import React from 'react'
import { Outlet } from 'react-router-dom'

function PostWraper() {
  return (
    <Outlet/>
  )
}

export default PostWraper
