import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_CATEGORY = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);

  if(data.icon){
    formData.append("icon", data.icon,"icon.png");
    formData.append("thumb", data.thumb,"thumb.png");
  }

  if (data.row) {
    formData.append("id", JSON.stringify(data.row.id));
  }

  const res = await POSTAPI(
    data.row ? API_URL.updateCategory : API_URL.addCategory,
    formData
  );

  return res;
};

export const GET_CATEGORY_LIST = async (c) => {
  const res = await GETAPI(API_URL.getCategoryList,"&count="+c);

  return res;
};
