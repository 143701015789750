import { API_URL, GETAPI, POSTAPI } from "./config";


export const GET_POST_LIST_BY_CATEGOREY = async (c,status,cId) => {
    const res = await GETAPI(API_URL.getPostByCategory,"&status="+status+"&category_id="+cId+"&count="+c);
  
    return res;
};

export const GET_REQUEST_POST_LIST = async (c,status) => {
    const res = await GETAPI(API_URL.getRequestPost,"&status="+status+"&count="+c);
  
    return res;
};

export const CHANGE_STATUS_POST = async (data) => {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("status", data.status);
  
  
    const res = await POSTAPI(
      API_URL.changePostStatus,
      formData
    );
  
    return res;
  };

  export const UPDATE_POST = async (data) => {
    const formData = new FormData();
    formData.append("id", data.id);
    
  
    formData.append("name",data.name);
    formData.append("price",data.price);
    formData.append("description",data.description);
    formData.append("category_id",data.category_id);
    formData.append("looking_for",data.looking_for);

    if(data.media.length){
      const mformData = new FormData();
      for(const  [index,val] of data.media.entries()){
        mformData.append(`media[${index}]`,val,`media${index}.png`);
        mformData.append(`thumb[${index}]`,data.thumb[index],`thumb${index}.png`);
        mformData.append(`type[${index}]`,1);
      }
      mformData.append("post_id",data.id);
      await POSTAPI(
        API_URL.updateMedia,
        mformData
      );
    }

    if(data.deletedMediaIds.length){
      const mformData = new FormData();
      mformData.append("ids",data.deletedMediaIds.join(","))
      await POSTAPI(
        API_URL.deleteMedia,
        mformData
      );
    }

    const res = await POSTAPI(
      API_URL.changePostStatus,
      formData
    );
  
    return res;
  };