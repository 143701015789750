import { Fab } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { GET_SCHOLL_LIST } from "../../../Apis/school_api";
import SplitButton from "../../../Component/Button/SplitButton";
import AddSchoolDailog from "../../../Component/Dailog/AddSchoolDailog";

function SchoolView() {
  const [rows, setRows] = React.useState([]);
  const [selectdRow, setSelectedRows] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOnClose = (p) => {
    setOpen(false);
    setSelectedRows(null);
   
    if (p) {
      setRows([]);
      getData();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onEdit = (id) => {
    const t = rows.filter((v) => v.id == id);
    setSelectedRows(t[0]);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
       
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
   
    {
      field: "address",
      flex: 1,
      headerName: "Address",
      width: 230,
      headerAlign: "left",
      align: "left",
    },
    // {
    //   field: "status",
    //   headerName: "Active/Inactive",
    //   width: 130,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => (
    //     <p style={{ textAlign: "start", whiteSpace: "normal" }}>
    //       {params.value == 1 ? "Active" : "InActivity"}
    //     </p>
    //   ),
    // },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={null}
          title={params.value}
          onEdit={onEdit}
        />
      ),
    },
    // { field: "synonym_name", headerName: "Synonym name", width: 130 },
  ];

  async function getData() {
    setIsLoading(true);
    const t = await GET_SCHOLL_LIST();

    if (t && t.s == 1) {
      setRows([...t.r]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <DataGrideView isLoading={isLoading} rows={rows} columns={columns} />
      <Fab
        onClick={handleOpen}
        sx={{
        
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "primary",
        }}
        size="medium"
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
      {open && (
        <AddSchoolDailog
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
        
        />
      )}
    </Box>
  );
}

export default SchoolView;
