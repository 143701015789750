import * as React from "react";
import { Settings } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
} from "@mui/material";

export default function RequestAccept({
  dataId,
  isPending = 0,
  isReject = 0,
  isActivate = 0,
  onSubmit
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Avatar
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        sx={{
          height: "35px",
          width: "35px",
          cursor: "pointer",
        }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        color="inherit"
      >
        <Settings />
      </Avatar>

      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 2,display:"flex",flexDirection:"column" }}>
                  {isActivate && (
                    <Button
                      variant="text"
                      onClick={() => {
                        onSubmit(dataId, 1);
                        handleClose();
                      }}
                    >Activate</Button>
                  )}
                  {isPending && (
                    <Button
                      variant="text"

                      onClick={() => {
                        onSubmit(dataId, 0);
                        handleClose();
                      }}
                    >Pending</Button>
                  )}
                  {isReject && (
                    <Button
                      variant="text"

                      onClick={() => {
                        onSubmit(dataId, 2);
                        handleClose();
                      }}
                    >Reject</Button>
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
