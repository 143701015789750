import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../../Apis/config';
import { CHANGE_STATUS_POST, GET_REQUEST_POST_LIST } from '../../../Apis/post_request_api';
import RequestAccept from '../../../Component/Button/RequestAccept';
import SplitButton from '../../../Component/Button/SplitButton';
import DataGrideView from '../../../Component/DataGrideView/DataGrideView';
import { ToastShow } from '../../../Component/Toast/Toast';

function PostRequest() {

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCall, setIsCall] = useState(true);


    const getData = async (v = 1) => {
        setIsLoading(true);

        const data = await GET_REQUEST_POST_LIST(v ? rows.length : 0,0);

        if (v) {

            setRows([...rows, ...(data.r ?? [])]);
        } else {
            setRows([...(data.r ?? [])]);
        }
        setIsLoading(false);
        if ((data.r ?? []).length == 0) {
            setIsCall(false);
        }
    };


    useEffect(() => {
        getData(0);
    }, []);

    const callBack = (l) => {
        if (l >= rows.length && isCall) {
            getData();
        }
    };


    const columns = [
        {
            field: "id",
            headerName: "Sr.",
            headerAlign: "center",
            align: "center",
            width: 50,
            renderCell: (params) => {
                return params.api.getRowIndex(params.row.id) + 1;
            },
        },
        {
            field: "name",
            headerName: "Pro. Name",
            headerAlign: "center",
            align: "center",
            width: 250,
        },
        {
            field: "price",
            headerName: "Pro. Price",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "description",
            headerName: "Pro. Description",
            headerAlign: "center",
            align: "center",
            width: 250,
        },
        {
            field: "category_name",
            headerName: "Cat. Name",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "user_name",
            headerName: "User Name",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "user_profile",
            headerName: "User Profile",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return <Avatar
                onClick={()=>window.open(`${API_URL.imageBaseUrl}${params.value}`)}
                    sx={{
                        height: "40px",
                        width: "40px",
                        cursor:"pointer"
                    }}
                    src={`${API_URL.imageBaseUrl}${params.value}`} />
            }
        },

        {
            field: "action",
            headerAlign: "center",
            align: "center",
            headerName: "Action",
            width: 120,
            renderCell: (params) => {
              return <RequestAccept
                dataId={params.row.id}
                onSubmit={onSubmit}
                isActivate={params.row.status == 1 ? null : 1}
                isPending={params.row.status == -1 ? 1 : null}
                isReject={params.row.status != -1 ? 1 : null}
              />
            }
          }
    ];

    const onSubmit = async (id, status) => {

        const res = await CHANGE_STATUS_POST({ id, status });
        if (res && res.s) {
          ToastShow("Success");
          getData(0);
        } else {
          ToastShow("Opps! something went wrong. Please try again.");
        }
      };


    return (

        <DataGrideView
            columns={columns}
            rows={rows}
            callBack={callBack}
            isLoading={isLoading}
        />)
}

export default PostRequest
