import React, { useEffect, useState } from 'react';
import DataGrideView from '../../../Component/DataGrideView/DataGrideView';
import { GET_USERS_LIST } from '../../../Apis/users_api';
import { Avatar, Typography } from '@mui/material';
import { API_URL } from '../../../Apis/config';



function Users() {

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCall, setIsCall] = useState(true);

    const getData = async (v = 1) => {
        setIsLoading(true);

        const data = await GET_USERS_LIST(v ? rows.length : 0);

        if (v) {

            setRows([...rows, ...(data.r ?? [])]);
        } else {
            setRows([...(data.r ?? [])]);
        }
        setIsLoading(false);
        if ((data.r ?? []).length == 0) {
            setIsCall(false);
        }
    };


    useEffect(() => {
        getData(0);
    }, []);

    const callBack = (l) => {
        if (l >= rows.length && isCall) {
            getData();
        }
    };


    const columns = [
        {
            field: "id",
            headerName: "Sr.",
            headerAlign: "center",
            align: "center",
            width: 50,
            renderCell: (params) => {
                return params.api.getRowIndex(params.row.id) + 1;
            },
        },

        {
            field: "profile",
            headerName: "Profile",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return <Avatar
                    sx={{
                        height: "50px",
                        width: "50px"
                    }}
                    src={`${API_URL.imageBaseUrl}${params.value}`} />
            }
        },
        {
            field: "name",
            headerName: "Name",
            headerAlign: "center",
            align: "center",
            width: 250,
        },

        {
            field: "phone",
            headerName: "Phone",
            headerAlign: "center",
            align: "center",
            width: 250,
            renderCell: (params) => {
                return <Typography>{params.row.c_code}{params.value}</Typography>
            }
        },
        {
            field: "graduat_year",
            headerName: "Graduate Year",
            headerAlign: "center",
            align: "center",
            width: 250,
        },

    ];


    return (
        <>
            <DataGrideView
                columns={columns}
                rows={rows}
                callBack={callBack}
                isLoading={isLoading}
            />

        </>
    )
}

export default Users
