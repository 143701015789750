import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { ADD_UPDATE_SCHOOL } from "../../Apis/school_api";
import LocationSearchInput from "../AutoComplate/AddressAutoComplate";
import TextFieldView from "../TextField/TextFieldView";
import ToastView, { ToastShow } from "../Toast/Toast";
// sx={{backdropFilter: "blur(1px)",}}
function AddSchoolDailog({ open, handleOnClose, row }) {

  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState(row ? row.name : "");
  const [address, setAddress] = React.useState(row ? row.address : "");
 const [latLng, setLatLng] = React.useState(
    row
      ? {
          lat: row.lat,
          lng: row.lng,
        }
      : {}
  );

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please fill name");
    } else if (!address) {
      ToastShow("Please fill address");
    } 
     else {
      setIsLoading(true);
      const res = await ADD_UPDATE_SCHOOL({
        name: name,
        latitude: latLng.lat,
        longitude: latLng.lng,
        address: address,
        row:row
      });
      setIsLoading(false);
      if (res && res.s == 1) {
        handleOnClose(1);
      }
    }
  };

  const handleName = (v) => {
    setName(v);
  };

  const handleAddress = (address) => {
    // console.log(address);
    setAddress(address);
  };


  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
    // console.log("lat",latLng.lat, "lng",latLng.lng);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>Add School</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ width: "350px", mt: 1 }}>
            <TextFieldView
              value={name}
              onchange={handleName}
              label="School name"
              type="text"
              sx={{ width: "100%" }}
            />
            <LocationSearchInput
              address={address}
              handleAddress={handleAddress}
              updateLatLng={updateLatLng}
            />
          </DialogContentText>
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleOnSubmit} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddSchoolDailog;
