import { Button, CircularProgress, Divider, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react';
import { ADD_UPDATE_CONFIG, GET_CONFIG_LIST } from '../../../Apis/config_api';

function Config() {

    const [android, setAndroid] = useState("");
    const [ios, setIos] = useState("");
    const [vemo, setVemo] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isSendLoading, setSendLoading] = useState(false);
  

    const handleAndroid = (event) => {
        event.preventDefault();
        setAndroid(event.target.value);
    }
    const handleVemo = (event) => {
        event.preventDefault();
        setVemo(event.target.value);
    }


    const handleIos = (event) => {
        event.preventDefault();
        setIos(event.target.value);
    }

    async function getData() {


        const data = await GET_CONFIG_LIST();

        if (data && data.s) {

            if (data.r.length) {
                const aKey = data.r.filter((v) => v.type === 2);
                const iKey = data.r.filter((v) => v.type === 1);
                const sKey = data.r.filter((v) => v.type === 3);
                const aValue = aKey.length ? aKey[0].value : "";
                const iValue = iKey.length ? iKey[0].value : "";
                const sValue = sKey.length ? sKey[0].value : "";
                setAndroid(aValue);
                setIos(iValue);
                setVemo(sValue);
            }
        }

    }

    const addKey = async () => {

        if (ios && android) {
            setLoading(true);

            await ADD_UPDATE_CONFIG(
                {

                    "type": 1,
                    "value": ios ?? ""
                }

            );

            await ADD_UPDATE_CONFIG(
                {

                    "type": 2,
                    "value": android ?? ""
                }

            );

            await ADD_UPDATE_CONFIG(
                {

                    "type": 3,
                    "value": vemo ?? ""
                }

            );
        }

        setLoading(false);
    }

    const addSendKey = async () => {

        if (vemo) {
            setSendLoading(true);

            await ADD_UPDATE_CONFIG(
                {

                    "type": 3,
                    "value": vemo ?? ""
                },

            );
        }

        setSendLoading(false);
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column"
            }}
        >

            {/* Config key MAP */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column"
                }}
            >

                <Typography
                    sx={{
                        mb: "15px"
                    }}
                    variant='h6'>Map Keys</Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "starts"

                    }}
                >
                    <TextField
                        onChange={handleIos}
                        value={ios}
                        sx={{ mr: "20px", mb: "20px", width: "400px" }}
                        label='Ios key'
                        variant='filled' placeholder='Enter Ios key' />
                    <TextField
                        onChange={handleAndroid}
                        value={android}
                        label='Android key'
                        sx={{ mr: "20px", mb: "20px", width: "400px" }}
                        variant='filled' placeholder='Enter Android key' />
                    {
                        isLoading ?
                            <CircularProgress />
                            : <Button variant='outlined' onClick={addKey}>Update</Button>
                    }


                </Box>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    height: "0.1px",
                    opacity: ".1",
                    mt: 3,
                    mb: 3,
                    backgroundColor: "divider"
                }}
            ></Box>

            {/* Config key Vemo */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column"
                }}
            >

                <Typography
                    sx={{
                        mb: "15px"
                    }}
                    variant='h6'>Vemo Account Key</Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "starts"

                    }}
                >
                    <TextField
                        onChange={handleVemo}
                        value={vemo}
                        sx={{ mr: "20px", mb: "20px", width: "400px" }}
                        label='Vemo account key'
                        variant='filled' placeholder='Vemo account key' />
                    {
                        isSendLoading ?
                            <CircularProgress />
                            : <Button variant='outlined' onClick={addSendKey}>Update</Button>
                    }


                </Box>
            </Box>


        </Box>
    )
}

export default Config
