import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_SCHOOL = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("status", data.status);
  formData.append("lat", data.latitude);
  formData.append("lng", data.longitude);
  formData.append("address", data.address);
  if (data.row) {
    formData.append("id", JSON.stringify(data.row.id));
  }

  const res = await POSTAPI(
    data.row ? API_URL.updateSchool : API_URL.addSchool,
    formData
  );

  return res;
};

export const GET_SCHOLL_LIST = async () => {
  const res = await GETAPI(API_URL.getSchoolList);

  return res;
};
