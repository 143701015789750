import axios from "axios";

export const API_URL = {
  baseUrl: "https://app.reuzapp.com/",
  
  sign_in: "api/auth/sign-in",
  
  getSchoolList: "api/school/get-list",
  addSchool: "api/auth/school/create",
  updateSchool: "api/school/update",

  getCategoryList: "api/category/get-list",
  addCategory: "api/category/create",
  updateCategory: "api/category/update",

  getUserList: "api/user/get-list",

  addUpdateConfig: "api/key-config/add-update",
  getConfig: "api/key-config/get-list",

  getPostByCategory: "api/post/get-post-by-category",
  getRequestPost: "api/post/get-post-by-status",
  changePostStatus: "api/post/update",
  deleteMedia: "api/post/delete-media",
  updateMedia: "api/post/update-media",
  getContactList: "api/contact-support/get-feedback",

  getReportedBlockedList: "api/user/report-block/get-list",

  imageBaseUrl:" https://reuse-media.s3.us-east-2.amazonaws.com/"

};

export const API = axios.create({ baseURL: API_URL.baseUrl });

export async function GETAPI(url, data = "") {
  try {
    const auths = `?apikey=${tokens.apikey}&token=${tokens.token}`;
    const result = await API.get(url + auths + data, {
      headers: null,
    });
   // console.log(result.data);
    return result.data;
  } catch (e) {
    //console.log(e);
    return null;
  }
}

export async function POSTAPI(url, data) {
  try {
    data.append("apikey", tokens.apikey);
    data.append("token", tokens.token);
    const result = await axios.post(API_URL.baseUrl + url, data, {
      headers: null,
    });
    //console.log(result.data);
    return result.data;
  } catch (e) {
    //console.log(e);
    return null;
  }
}

const tokens = {};
export default tokens;
