import { Avatar, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { GET_CATEGORY_LIST } from '../../../Apis/catrgory_api';
import { API_URL } from '../../../Apis/config';
import { CHANGE_STATUS_POST, GET_POST_LIST_BY_CATEGOREY } from '../../../Apis/post_request_api';
import RequestAccept from '../../../Component/Button/RequestAccept';
import SplitButton from '../../../Component/Button/SplitButton';
import DataGrideView from '../../../Component/DataGrideView/DataGrideView';
import MultiSelection from '../../../Component/Multiselection/MultiSelection';
import { ToastShow } from '../../../Component/Toast/Toast';
import EditPostDailog from "../../../Component/Dailog/EditPostDailog"

function Post() {

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCall, setIsCall] = useState(true);
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectdRow, setSelectedRows] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClose = (v) => {
        setOpen(false);

        if (v) {
            console.log("data");
            console.log("data", selectedCategory[0]);
            getData(0, selectedCategory[0]);
        }
    }

    const getData = async (v = 1, category) => {
        setIsLoading(true);

        const cId = getCategoryId(category);

        const data = await GET_POST_LIST_BY_CATEGOREY(v ? rows.length : 0, "1,0,2", cId);

        if (v) {

            setRows([...rows, ...(data.r ?? [])]);
        } else {
            setRows([...(data.r ?? [])]);
        }
        setIsLoading(false);
        if ((data.r ?? []).length == 0) {
            setIsCall(false);
        }
    };

    const getCategoryId = (name) => {
        const d = category.filter(v => v.name == name);
        return d[0].id;
    }

    const getCategory = async () => {
        const data = await GET_CATEGORY_LIST(0);

        if (data && data.s && data.r.length) {
            setCategory([...data.r]);
            setSelectedCategory([data.r[0].name]);
        }

    }

    const handleChange = (e) => {
        const {
            target: { value },
        } = e;
        setSelectedCategory([value]);
        getData(0, value);
    }


    useEffect(() => {
        if (category.length) {

            getData(0, category[0].name);
        }
    }, [category])

    useEffect(() => {
        getCategory();
    }, []);

    const callBack = (l) => {
        if (l >= rows.length && isCall) {
            getData(1, selectedCategory[0]);
        }
    };


    const columns = [
        {
            field: "id",
            headerName: "Sr.",
            headerAlign: "center",
            align: "center",
            width: 80,
            renderCell: (params) => {
                return params.api.getRowIndex(params.row.id) + 1;
            },
        },
        {
            field: "name",
            headerName: "Pro. Name",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "price",
            headerName: "Pro. Price",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "description",
            headerName: "Pro. Description",
            headerAlign: "center",
            align: "center",
            width: 250,
        },
        {
            field: "category_name",
            headerName: "Cat. Name",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "user_name",
            headerName: "User Name",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "user_profile",
            headerName: "User Profile",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return <Avatar
                    onClick={() => window.open(`${API_URL.imageBaseUrl}${params.value}`)}
                    sx={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer"
                    }}
                    src={`${API_URL.imageBaseUrl}${params.value}`} />
            }
        },
        {
            field: "status",
            headerAlign: "center",
            align: "center",
            headerName: "Status",
            width: 120,
            renderCell: (params) => {
                return (
                    <Typography
                        sx={{
                            color: getColor(params.value)
                        }}
                    >
                        {params.value === 0
                            ? "Pending"
                            : params.value === 1
                                ? "Activated"
                                : params.value === 2
                                    ? "Rejected"
                                    : ""}
                    </Typography>
                );
            },
        },
        {
            field: "action",
            headerAlign: "center",
            align: "center",
            headerName: "Status Action",
            width: 120,
            renderCell: (params) => {
                return <RequestAccept
                    dataId={params.row.id}
                    onSubmit={onSubmit}
                    isActivate={params.row.status == 1 ? null : 1}
                    isPending={params.row.status >= 1 ? 1 : null}
                    isReject={(params.row.status != -1 && params.row.status != 2) ? 1 : null}
                />
            }
        },
        {
            field: "action status",
            headerName: "Action",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => (
                <SplitButton
                    key={params.id}
                    dataId={params.id}
                    isDelete={null}
                    title={params.value}
                    onEdit={onEdit}
                />
            ),
        },
    ];

    const onEdit = (id) => {
        const t = rows.filter((v) => v.id == id);
        setSelectedRows(t[0]);
        setOpen(true);
    };

    const getColor = (value) => {

        if (value == 0) {
            return "#FFA500";
        } else if (value == 1) {
            return "#6BA57D";
        } else if (value == 2) {
            return "#ff0026";
        } else {
            return "#800000";
        }
    }

    const onSubmit = async (id, status) => {

        const res = await CHANGE_STATUS_POST({ id, status });
        if (res && res.s) {
            ToastShow("Success");
            getData(0, selectedCategory[0]);
        } else {
            ToastShow("Opps! something went wrong. Please try again.");
        }
    };


    return (

        <Box>
            {
                category &&
                <MultiSelection
                    handleChange={handleChange}
                    lable="Categories"
                    names={category}
                    multiple={false}
                    selected={selectedCategory}
                />}
            <DataGrideView
                columns={columns}
                rows={rows}
                callBack={callBack}
                isLoading={isLoading}

            />
            {
                open && <EditPostDailog handleClose={handleClose} category={category} open={open} row={selectdRow} />
            }
        </Box>
    )
}

export default Post
