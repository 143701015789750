import React from 'react'

function PostEdit() {
  return (
    <div>
      Edit
    </div>
  )
}

export default PostEdit
