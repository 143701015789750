import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { theme } from "./Utils/theme";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/system";
import { BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import AuthContext from "./Context/AuthContext";
import AuthService from "./Service/AuthService";
import SchoolView from "./Pages/Screens/School/SchoolView";
import LoginAuth from "./Pages/Auth/LoginAuth";
import ToastView from "./Component/Toast/Toast";
import Category from "./Pages/Screens/Category/Category";
import Users from "./Pages/Screens/Users/Users";
import Config from "./Pages/Screens/Config/Config";
import PostRequest from "./Pages/Screens/Post/PostRequest";
import Post from "./Pages/Screens/Post/Post";
import PostWraper from "./Pages/Screens/Post/PostWraper";
import PostEdit from "./Pages/Screens/Post/PostEdit";
import ContactUs from "./Pages/Screens/ContactUs/ContactUs";
import BlockedUser from "./Pages/Screens/Users/BlockedUser";
import ReportedUser from "./Pages/Screens/Users/ReportedUser";

function App() {
  // THEME MODE
  const [mode, setMode] = useState("light");

  // CHNAGE THEME MODE
  const changeMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  // MATERIAL UI SETUP
  return (
    <>
      <AuthContext>
        <ThemeProvider theme={theme(mode)}>
          <BrowserRouter>
            <Box
              sx={{
                overflow: "hidden",
                bgcolor: "background.default",
                height: "100vh",
                width: "100vw",
                overflowX: "hidden",
              }}
            >
              {/* ROUTES */}
              <Routes>
                <Route path="/" element={<LoginAuth />} />
                <Route
                  path="users"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Users"
                        children={<Users />}
                      />
                    </AuthService>
                  }
                />
                 <Route
                  path="reported-user"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Reported User"
                        children={<ReportedUser />}
                      />
                    </AuthService>
                  }
                />
                 <Route
                  path="blocked-user"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Blocked User"
                        children={<BlockedUser />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="school"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="School"
                        children={<SchoolView />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="category"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Category"
                        children={<Category />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="post"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Post"
                        children={<PostWraper />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<Post />} />
                  <Route path="edit/:id" element={<PostEdit />} />
                </Route>

                <Route
                  path="post-request"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Post Request"
                        children={<PostRequest />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="config"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Category"
                        children={<Config />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="contact-us"
                  element={
                     <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Contact-us"
                        children={<ContactUs />}
                      />
                    </AuthService>
                  }
                />
              </Routes>
            </Box>
          </BrowserRouter>
        </ThemeProvider>
      </AuthContext>
      <ToastView />
    </>
  );
}

export default App;
