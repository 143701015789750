import React, { useEffect, useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { Avatar, Typography } from "@mui/material";
import { API_URL } from "../../../Apis/config";
import { GET_USER_ACTION_LIST } from "../../../Apis/users_action_api";

function ReportedUser() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (v = 1) => {
    setIsLoading(true);

    const data = await GET_USER_ACTION_LIST(v ? rows.length : 0,2);

    if (v) {
      setRows([...rows, ...(data.r ?? [])]);
    } else {
      setRows([...(data.r ?? [])]);
    }
    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData(0);
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      headerAlign: "center",
      align: "center",
      width: 50,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "report_by",
      headerName: "Reported By",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell:({params})=>{
        return <Typography>{params.row.from_user?.name}</Typography>
      }
    },
    {
        field: "report_to",
        headerName: "Reported To",
        headerAlign: "center",
        align: "center",
        width: 250,
        renderCell:({params})=>{
          return <Typography>{params.row.to_user?.name}</Typography>
        }
      },
    {
      field: "reason",
      headerName: "Reason",
      headerAlign: "center",
      align: "center",
      width: 250,
    },
  ];

  return (
    <>
      <DataGrideView
        columns={columns}
        rows={rows}
        callBack={callBack}
        isLoading={isLoading}
      />
    </>
  );
}

export default ReportedUser;
