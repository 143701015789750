import { API_URL, GETAPI, POSTAPI } from "./config";


export const ADD_UPDATE_CONFIG = async (data) => {
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("value", data.value);
  
  
    const res = await POSTAPI(
       API_URL.addUpdateConfig ,
      formData
    );
  
    return res;
  };

  export const GET_CONFIG_LIST = async () => {
    const res = await GETAPI(API_URL.getConfig);
  
    return res;
  };
  
