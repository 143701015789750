import { Delete, Upload } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { API_URL } from '../../Apis/config';
import MultiSelection from '../Multiselection/MultiSelection';
import TextFieldView from '../TextField/TextFieldView';
import Compressor from 'compressorjs';
import { ToastShow } from '../Toast/Toast';
import { UPDATE_POST } from '../../Apis/post_request_api';

function EditPostDailog({ row, handleClose, open, category }) {

    const [data, setData] = useState({
        name: row.name,
        price: row.price,
        media: row.media,
        selectedImage: [],
        selectedThumb:[],
        description: row.description,
        deletedMediaIds: [],
        categoryId: row.category_id,
        isLooking: row.looking_for,
        address: row.address,
        lat: row.lat,
        lng: row.lng,
        selectdRow: category.filter((v) => v.id == row.category_id)[0],
        selectedCategory: [category.filter((v) => v.id == row.category_id)[0].name]
    });

    console.log(category.filter((v) => v.id == row.category_id)[0]);

    const [isLoading,setLoading] = useState(false);

    const handleChange = (e) => {
        const {
            target: { value },
        } = e;
        setData(p => ({
            ...p, selectedCategory: [value], selectdRow: category.filter((v) => v.name == value)[0]
        }))
    }

    const onSubmit = async () => {

        if(!data.media.length && !data.selectedImage.length){
            ToastShow("Please select image");
        }
        else if(!data.name){
            ToastShow("Please fill name");
        }else if(!data.price) {
            ToastShow("Please fill price");
        }else if(!data.description){
            ToastShow("Please fill description");
        }else{
            setLoading(true);
            const res = await UPDATE_POST({
                id:row.id,
                category_id:data.selectdRow.id,
                media:data.selectedImage,
                thumb:data.selectedThumb,
                name:data.name,
                description:data.description,
                price:data.price,
                deletedMediaIds:data.deletedMediaIds,
                looking_for:data.isLooking
            });

            if(res && res.s){
                handleClose(1);
            }else {
                ToastShow(res ? res.m : "Opps! something went wrong please try again.");
            }
            setLoading(false);
        }
        
    }

    return (
        <Dialog
            
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Edit post
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <Box
                display="flex"
                flexWrap="wrap"
                >
                    {
                       data.media.map((v,index)=>{
                        return <Box
                        key={index}
                        sx={{
                            overflow:"visible",
                            position:"relative"
                        }}
                        >
                        <img
                        loading='lazy'
                        style={{marginRight:"15px"}}
                        height="70px"
                        width="70px"
                        src={API_URL.imageBaseUrl+v.thumb}
                        />
                        <IconButton
                        size='small'
                        onClick={()=>{

                            setData(p=>({
                                ...p,
                                deletedMediaIds:[...data.deletedMediaIds,data.media[index].id],
                                media:data.media.filter((v)=>v.id != data.media[index].id),
                            }))

                        }}
                        sx={{
                            height:"22px",
                            width:"22px",
                            backgroundColor:"red",
                            position:"absolute",
                            top:"0px",
                            right:"5px"
                        }}
                        >
                            <Delete sx={{color:"#fff",fontSize:"15px"}}/>
                        </IconButton>
                        </Box>
                       })
                    }

{
                       data.selectedImage.map((v,index)=>{
                        return <Box
                        key={index}
                        sx={{
                            overflow:"visible",
                            position:"relative"
                        }}
                        >
                        <img
                        loading='lazy'
                        style={{marginRight:"15px"}}
                        height="70px"
                        width="70px"
                        src={URL.createObjectURL(v)}
                        />
                        <IconButton
                        size='small'
                        onClick={()=>{

                            setData(p=>({
                                ...p,
                                selectedImage:data.selectedImage.filter((v)=>v != data.selectedImage[index]),
                                selectedThumb:data.selectedThumb.filter((v)=>v != data.selectedThumb[index]),
                            }))

                        }}
                        sx={{
                            height:"22px",
                            width:"22px",
                            backgroundColor:"red",
                            position:"absolute",
                            top:"0px",
                            right:"5px"
                        }}
                        >
                            <Delete sx={{color:"#fff",fontSize:"15px"}}/>
                        </IconButton>
                        </Box>
                       })
                    }

                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label">
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                                if (event.target.files) {
                                    new Compressor(event.target.files[0], {
                                        quality: 0.8,
                                        success: (compressedResult) => {
                                            const t = data.selectedImage;
                                            t.push(compressedResult);
                                            setData(p=>({...p,
                                                selectedImage:[...t]
                                            }));
                                           
                                        },
                                    });
                                    new Compressor(event.target.files[0], {
                                        quality: 0.5,
                                        success: (compressedResult) => {

                                            const t = data.selectedThumb;
                                            t.push(compressedResult);
                                            setData(p=>({...p,
                                                selectedThumb:[...t]
                                            }));
                                        },
                                    });
                                }

                            }}
                        />
                        <Upload />
                    </IconButton>

                </Box>

                <TextFieldView
                    sx={{
                        width: "90%",
                        mt:1
                    }}
                    label="Name"
                    value={data.name}
                    onchange={(e) => setData(p => ({ ...p, name: e }))}
                />

                <TextFieldView
                    label="Price"
                    type='number'
                    sx={{
                        my: 1,
                        width: "90%"
                    }}
                    value={data.price}
                    onchange={(e) => setData(p => ({ ...p, price: e }))}
                />

                <TextField
                    label="description"
                    rows={4}
                    multiline
                    maxRows={4}
                    sx={{
                        width: "82%"

                    }}
                    value={data.description}
                    onChange={(e) => setData(p => ({ ...p, description: e.target.value }))}
                />
                <MultiSelection
                    sx={{
                        mt: 2
                    }}
                    width="82%"
                    handleChange={handleChange}
                    lable="Categories"
                    names={category}
                    multiple={false}
                    selected={data.selectedCategory}
                />
                <FormControlLabel
                label="Is looking for someone?"
                control={<Checkbox checked={data.isLooking ? true : false}  onChange={(e)=>{
                    setData(p=>({...p,isLooking:e.target.checked ? 1 : 0}));
                    console.log(e.target.checked)
                }}/>}
                />
            </DialogContent>

            <DialogActions>
              {
                isLoading ? <CircularProgress /> : <Button variant='text' onClick={onSubmit}>Edit</Button>
              }  
                <Button variant='text' onClick={() => handleClose(0)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditPostDailog
