import { Block, Category, Contactless, DynamicFeed, People, PostAdd, Report, RequestPage, Settings } from '@mui/icons-material';
import LocationCityIcon from '@mui/icons-material/LocationCity';


export const MenuItems = [
  {
    id: "1",
    path: "/users",
    isExpand: false,
    icon: <People color="#9bc2a7" />,
    title: "Users",
  },
  {
    id: "9",
    path: "/reported-user",
    isExpand: false,
    icon: <Report color="#9bc2a7" />,
    title: "Reported User",
  },
  {
    id: "8",
    path: "/blocked-user",
    isExpand: false,
    icon: <Block color="#9bc2a7" />,
    title: "Blocked User",
  },
  {
    id: "2",
    path: "/school",
    isExpand: false,
    icon: <LocationCityIcon color="#9bc2a7" />,
    title: "School",
  },
  {
    id: "3",
    path: "/category",
    isExpand: false,
    icon: <Category color="#9bc2a7" />,
    title: "Category",
  },
  {
    id: "4",
    path: "/post-request",
    isExpand: false,
    icon: <PostAdd color="#9bc2a7" />,
    title: "Post Request",
  },
  {
    id: "5",
    path: "/post",
    isExpand: false,
    icon: <DynamicFeed color="#9bc2a7" />,
    title: "Post",
  },
  {
    id: "6",
    path: "/config",
    isExpand: false,
    icon: <Settings color="#9bc2a7" />,
    title: "Config",
  },
  {
    id: "7",
    path: "/contact-us",
    isExpand: false,
    icon: <Contactless color="#9bc2a7" />,
    title: "Contact-us",
  },
];
